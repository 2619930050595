import React from 'react'
import '../Styles/ContactUs.css'
import Logo from '../Media/logo.png'
import AudioOn from '../Media/audio_on.png'
import AudioOff from '../Media/audio_off.png'
import TextoOn from '../Media/texto_on.png'
import TextoOff from '../Media/texto_off.png'
import ImagenOn from '../Media/imagen_on.png'
import ImagenOff from '../Media/imagen_off.png'
import { useState } from 'react'
import InfoSender from './InfoSender'

export default function ContactUs() {   

    let cartaExtra = localStorage.getItem('cartaExtra')
    let carta1 = localStorage.getItem('carta1')
    let carta2 = localStorage.getItem('carta2')
    let carta3 = localStorage.getItem('carta3')
    let cartas = [carta1, carta2, carta3]

    const [state, setState] = useState(0)

    const [data, setData] = useState({
        userName: '',
        userAccount: ''
    })

    function handleOnChange(e){
        e.preventDefault()
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
        console.log(data)
    }

    function swap (){
        if(state !== 0){
            document.getElementsByClassName('textinfosender')[0].style.display = 'inline'
            document.getElementsByClassName('divinputs')[0].style.display = 'none'
            document.getElementsByClassName('howgonnatell')[0].style.display = 'none'
            document.getElementsByClassName('buttonImgDiv')[0].style.display = 'none'
            document.getElementsByClassName('siguienteEndButton')[0].style.display = 'none'
        }
    }
 
    return (
        <div className='contactUsDiv'>
            <div className='divcardsend'>
                <img className='TitleImgend' alt='Logo' src={Logo}></img>
                <div className='enddivcardcontainer'>
                {
                    cartas.map((e) => {
                        return(
                            <div className='endcard'>
                                {/* <img className='cardimg' alt='tarotcartita' src={front}></img> */}
                                <h2 className='innertext'>{e}</h2>
                            </div>
                        )
                    })
                }
                {cartaExtra ?<div className='endcard'>
                    {/* <img className='cardimg' alt='tarotcartita' src={front}></img> */}
                    <h2 className='innertext'>{cartaExtra}</h2>
                </div>: <p></p>}
                </div>
            </div>
            <div className='sendinfodivend'>
                <h1 className='tellyourstory'>Tiempo de relatar tu historia!</h1>
                <div className='divinputs'>
                    <input name='userName' onChange={e => handleOnChange(e)} className='inputInfo' type='text' placeholder='Nombre Completo'/>   
                    <input name='userAccount' onChange={e => handleOnChange(e)} className='inputInfo' type='text' placeholder='Usuario de Instagram'/>   
                </div>
                <h1 className='howgonnatell'>Como vas a contar tu historia?</h1>
                <div className='buttonImgDiv'>
                    <img src={state === 1? ImagenOn: ImagenOff} onClick={() => setState(1)} alt="imagen" className="imgdatabutton" />
                    <img src={state === 2? TextoOn: TextoOff} onClick={() => setState(2)} alt="texto" className="imgdatabutton" />
                    {/* <img src={state === 3? AudioOn: AudioOff} onClick={() => setState(3)} alt="audio" className="imgdatabutton" /> */}
                </div>
                <div className='textinfosender'>
                    {
                        <InfoSender userName={data.userName} userAccount={data.userAccount} number={state}></InfoSender>
                    }
                </div>
                <button disabled={state === 0 || data.userAccount === '' || data.userName === ''} className='siguienteEndButton' onClick={() => {swap()}}>Siguiente</button>
            </div>
        </div>
    )
}
