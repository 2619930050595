import '../Styles/infoSender.css'
import React from 'react'
import { useState } from 'react';
import {getFirestore, setDoc, doc} from 'firebase/firestore'
import {ref, uploadBytes, getStorage} from 'firebase/storage'
import app from '../Data/FireBase';



export default function InfoSender({number, userName, userAccount}) {

    const db = getFirestore(app)

    const ImgDb = getStorage(app)

    const [state, setState] = useState({
        story: ''
    })

    const [file, setFile] = useState(null)

    function handleOnChange(e){
        e.preventDefault()
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    function yaNoseQueNombrePoner(e){
        e.preventDefault()
        document.getElementById('inputfile').click()
    }

    function goBack(){
        document.getElementsByClassName('textinfosender')[0].style.display = 'none'
        document.getElementsByClassName('divinputs')[0].style.display = 'flex'
        document.getElementsByClassName('howgonnatell')[0].style.display = 'inline'
        document.getElementsByClassName('buttonImgDiv')[0].style.display = 'flex'
        document.getElementsByClassName('siguienteEndButton')[0].style.display = 'inline'
    }

    async function sendData(coleccion){
        await setDoc(doc(coleccion, 'storyCardsUsersData',userName), {
            Story: state.story,
            userAccount: userAccount,
            userName: userName
        })
        console.log(userAccount)
    }

    async function sendImage(){
        const imgRef = ref(ImgDb, `images/${file.name + userName}`)
        uploadBytes(imgRef, file).then(() => {
            alert('imagen subida correctamente')
        })
    }


    if(number === 2){
        return(
            <div className='generalDivInfoSender'>
               <textarea onChange={e => handleOnChange(e)} name='story' className='textareainfosender' placeholder='&nbsp;Escribe Aqui...'></textarea>
               <h4 id='Disclaimer'>El autor de la historia va a ser etiquetado en las redes sociales.</h4>
               <div className='textsenderbuttondiv'>
                    <button  className="backandendtextsender" onClick={() => {goBack()}}>Atras</button>
                    <button disabled={state.story === ''} className="backandendtextsender" onClick={() => {sendData(db)}}>Enviar</button>
               </div>
            </div>
        )
    }else if(number === 1){
        return(
            <div className='generalDivImgSender'>
                <div className="imginputcontainer">
                    <input accept="image/*" id='inputfile' type='file' onChange={(e) => {setFile(e.target.files[0])}}/>
                    <input value='Seleccionar' type='button' className='handlebuttoninputfile' onClick={e => yaNoseQueNombrePoner(e)}/>
                </div>
                <h4 id='Disclaimer'>El autor de la historia va a ser etiquetado en las redes sociales.</h4>
                <div className='textsenderbuttondiv'>
                    <button  className="backandendtextsender" onClick={() => {goBack()}}>Atras</button>
                    <button onClick={() => sendImage()} disabled={file === null} className="backandendtextsender" >Enviar</button>
               </div>
            </div>
        )
    }
}