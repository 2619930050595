import React from 'react'
import '../Styles/Landing.css'
import { Link } from 'react-router-dom'
import Logo from '../Media/logo.png'

export default function Landing() {
    return (
        <div className='LandingDiv'>
            <img className='TitleDiv' alt='Logo' src={Logo}></img>
            <div className='DescriptionDiv'>
                <h3>Este juego esta hecho para dejar volar la imaginacion y pasar un buen rato con tus amigos!<br></br>
                <br></br> 
                    Se te presentaran <ins>3 cartas</ins>, cada una de una categoria diferente: <ins>Lugar, Personaje y Accion.</ins> Puedes elegir una carta mas de <ins> solo una Categoria</ins><br></br>
                <br></br>
                    las cartas puedendser interpretadas de la manera que cada jugador considere la mejor para crear historias
                </h3>
            </div>
            <h1 className='DivImaginacion'>Deja que tu imaginacion vuele!</h1>
            <Link to='/home'>
                <button className='btnIniciar'>Empezar</button>
            </Link>
        </div>
    )
}
