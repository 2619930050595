import  {initializeApp} from "firebase/app";


const firebaseConfig = {
    apiKey: "AIzaSyBSoYo1O_y2qIjl-LpkIjseO20it67gXmg",
    authDomain: "storycards-347bf.firebaseapp.com",
    projectId: "storycards-347bf",
    storageBucket: "storycards-347bf.appspot.com",
    messagingSenderId: "998380156260",
    appId: "1:998380156260:web:10cc804603751d79f53671",
    measurementId: "G-1N41D1RVBQ"
};

const app = initializeApp(firebaseConfig)


export default app