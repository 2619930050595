import React from 'react'
import '../Styles/Card.css'
import oneCardMore from '../Media/+.png'


export default function Card({front,front2, Back, idCard}) {

  const apear = (e) => {
    e.preventDefault()
    let element = document.getElementsByClassName('flip-card2')
    localStorage.setItem('cartaExtra', element[idCard].getElementsByClassName('h1cardname2')[0].innerHTML)
    element[idCard].style.display = ''
    let buttons = document.getElementsByClassName('addcartButton')
    for(let i = 0; i < buttons.length; i++){
      buttons[i].style.display = 'none'
    }
  } 

    return (
    <div>
      <div className='flip-card'>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img className='cardimgback' alt='tarotcartita' src={Back}></img>
          </div>
          <div className="flip-card-back">
            {/* <img className='cardimg' alt='tarotcartita' src={front}></img> */}
            <h1 className='h1cardname1' style={{color: 'black'}}>{front}</h1>
          </div>
        </div>
      </div>
      <div className='flip-card2' style={{display: 'none'}}>
        <div className="flip-card-inner2">
          <div className="flip-card-front2">
            <img className='cardimgback' alt='tarotcartita' src={Back}></img>
          </div>
          <div className="flip-card-back2">
            {/* <img className='cardimg' alt='tarotcartita' src={front}></img> */}
            <h1 className='h1cardname2' style={{color: 'black'}}>{front2}</h1>
          </div>
        </div>
      </div>
      <div className='addcartbuttondiv'>
        <img onClick={apear} className='addcartButton' src={oneCardMore} alt="oneMoreCard"/>
      </div>
    </div>
    )
}
