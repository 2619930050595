const Medieval = {
    places: [
        {
            id: '1',
            name: 'La Cuidad'
        },
        {
            id: '2',
            name: 'La Villa'
        },
        {
            id: '3',
            name: 'Los Suburbios'
        },
        {
            id: '4',
            name: 'El Bosque'
        },
        {
            id: '5',
            name: 'La cueva'
        },
        {
            id: '6',
            name: 'El Castillo'
        },
        {
            id: '7',
            name: 'La Montaña'
        },
        {
            id: '8',
            name: 'La Montaña'
        },
        {
            id: '9',
            name: 'La Montaña'
        },
        {
            id: '10',
            name: 'La Montaña'
        },
        {
            id: '11',
            name: 'La Montaña'
        },
        {
            id: '12',
            name: 'La Montaña'
        },
    ],
    characters: [
        {
            id: '1',
            name: 'El Bardo'
        },
        {
            id: '2',
            name: 'La Hechicera'
        },
        {
            id: '3',
            name: 'El Guerrero'
        },
        {
            id: '4',
            name: 'El Barbaro'
        },
        {
            id: '5',
            name: 'La Druida'
        },
        {
            id: '6',
            name: 'La Exploradora'
        },
        {
            id: '7',
            name: 'El Brujo'
        },
        {
            id: '8',
            name: 'La Clerigo'
        },
        {
            id: '9',
            name: 'El Mago'
        },
        {
            id: '10',
            name: 'El Paladin'
        },
        {
            id: '11',
            name: 'La Monje'
        },
        {
            id: '12',
            name: 'La Picara'
        },
        
    ],
    actions: [
        {
            id:'1',
            name: 'Ayudar'
        },
        {
            id:'2',
            name: 'Asesinar'
        },
        {
            id:'3',
            name: 'Esconder'
        },
        {
            id:'4',
            name: 'Salvar'
        },
        {
            id:'5',
            name: 'Ignorar'
        },
        {
            id:'6',
            name: 'Recibir Mission'
        },
        {
            id:'7',
            name: 'Robar'
        },
        {
            id:'8',
            name: 'Transformar'
        },
        {
            id:'9',
            name: 'Regalar'
        },
        {
            id:'10',
            name: 'Perseguir'
        },
        {
            id:'11',
            name: 'Escapar'
        },
        {
            id:'12',
            name: 'Beber/Comer'
        },
    ]

}


export {Medieval}